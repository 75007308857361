export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    /*
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    */
    {
      name: 'devices',
      displayName: 'menu.devices',
      meta: {
        icon: 'devices',
      },
    },
    {
      name: 'map',
      displayName: 'menu.map',
      meta: {
        icon: 'location_on',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.integrations',
      meta: {
        icon: 'api',
      },
      children: [
        {
          name: 'policies',
          displayName: 'menu.policies',
        },

        {
          name: 'api-tokens',
          displayName: 'menu.tokens',
        },
        {
          name: 'api',
          displayName: 'menu.api',
        },
      ],
    },

    /*
    {
      name: 'auth',
      displayName: 'menu.auth',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'login',
          displayName: 'menu.login',
        },
        {
          name: 'signup',
          displayName: 'menu.signup',
        },
        {
          name: 'recover-password',
          displayName: 'menu.recover-password',
        },
      ],
    },

    */

    /*
    {
      name: '404',
      displayName: 'menu.404',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    */

    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
